import React from "react"
import PropTypes from "prop-types"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Cushion from "../components/ui/Cushion"
import MaxWidth from "../components/ui/MaxWidth"
import Rectangle from "../components/ui/Rectangle"
import Typography from "../components/ui/Typography"
import ConvexTop from "../components/ConvexTop"
import ConcaveBottom from "../components/ConcaveBottom"
import NoImageSection from "../components/ContentSections/NoImage"
import ImageLeftSection from "../components/ContentSections/ImageLeft"
import ImageRightSection from "../components/ContentSections/ImageRight"

function isOdd(value) {
  if (value % 2 === 0) return false
  else return true
}

const ExplainPowerflush = ({ data, location }) => {
  const {
    explainPowerflushJson: { title, overview, sections } = {},
    contactDetailsJson,
  } = data
  const sectionsLength = sections ? sections.length : 0

  return (
    <Frame
      currentPathname={location.pathname}
      footerBackgroundColor={isOdd(sectionsLength) ? "white" : "vLightGrey"}
      contactDetails={contactDetailsJson}
    >
      <SEO title={title} />
      <ThermalBackground component="div">
        <ConcaveBottom fill="white">
          <Cushion
            component="div"
            horizontal="small"
            top="xxlarge"
            bottom="xxxxlarge"
            responsive={[
              { minWidth: "palm", props: { horizontal: "medium" } },
              { minWidth: "tab", props: { horizontal: "xlarge" } },
            ]}
          >
            <Typography component="div" align="center">
              <Cushion bottom="large">
                <Cushion bottom="medium">
                  <Typography component="div" preset="hero" color="white">
                    {title}
                  </Typography>
                </Cushion>
                <MaxWidth maxWidth={800} center>
                  <Typography component="div" preset="body" color="white">
                    {overview}
                  </Typography>
                </MaxWidth>
              </Cushion>
            </Typography>
          </Cushion>
        </ConcaveBottom>
      </ThermalBackground>

      {!!sectionsLength &&
        sections.map(({ title, content, image }, index) => {
          if (isOdd(index)) {
            return (
              <ConcaveBottom fill="white">
                <ConvexTop fill="vLightGrey" background="white">
                  <Cushion horizontal="large" vertical="xlarge">
                    <Rectangle fill="vLightGrey" component="div">
                      <MaxWidth component="div" maxWidth={980} center>
                        {image ? (
                          <ImageLeftSection
                            title={title}
                            content={content}
                            image={image}
                          />
                        ) : (
                          <NoImageSection title={title} content={content} />
                        )}
                      </MaxWidth>
                    </Rectangle>
                  </Cushion>
                </ConvexTop>
              </ConcaveBottom>
            )
          }
          return (
            <Cushion horizontal="large" vertical="xlarge">
              <Rectangle fill="white" component="div">
                <MaxWidth component="div" maxWidth={980} center>
                  {image ? (
                    <ImageRightSection
                      title={title}
                      content={content}
                      image={image}
                    />
                  ) : (
                    <NoImageSection title={title} content={content} />
                  )}
                </MaxWidth>
              </Rectangle>
            </Cushion>
          )
        })}
    </Frame>
  )
}

ExplainPowerflush.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export default ExplainPowerflush

export const pageQuery = graphql`
  query ExplainPowerflushQuery {
    contactDetailsJson {
      email
      mobile
      landline
    }
    explainPowerflushJson {
      title
      overview
      sections {
        title
        content
        image
      }
    }
  }
`
