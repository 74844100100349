import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Flex from "../ui/Flex"
import Typography from "../ui/Typography"
import MaxWidth from "../ui/MaxWidth"

const ImageLeft = ({ content, title, image }) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column-reverse"
      responsive={[
        {
          minWidth: "tab",
          props: {
            flexDirection: "row",
            alignItems: "center",
          },
        },
      ]}
    >
      <Cushion
        component="div"
        responsive={[
          {
            minWidth: "tab",
            props: {
              right: "medium",
            },
          },
        ]}
      >
        <MaxWidth component="div" maxWidth={500} style={{ minWidth: 300 }}>
          <img src={image} style={{ width: "100%" }} />
        </MaxWidth>
      </Cushion>
      <Typography component="div" align="left" style={{ flexGrow: 1 }}>
        <Cushion bottom="large">
          <Cushion bottom="medium">
            <Typography component="div" preset="subheading" color="black">
              {title}
            </Typography>
          </Cushion>
          <Typography preset="body" color="black">
            {content}
          </Typography>
        </Cushion>
      </Typography>
    </Flex>
  )
}

ImageLeft.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}
export default ImageLeft
