import React from 'react'
import PropTypes from 'prop-types'
import Cushion from "../ui/Cushion"
import Typography from "../ui/Typography"

const NoImage = ({content, title}) => {
  return (
    <Typography component="div" align="center">
    <Cushion bottom="large">
      <Cushion bottom="medium">
        <Typography
          component="div"
          preset="subheading"
          color="black"
        >
          {title}
        </Typography>
      </Cushion>
      <Typography preset="body" color="black">
        {content}
      </Typography>
    </Cushion>
  </Typography>
  )
}

NoImage.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}
export default NoImage;
